import { useSdk } from '~/sdk';
import type {
  GetCartItemsResponse,
  UpdateCartItemRequest,
  AddOrRemoveCouponRequest,
} from '@vue-storefront/prestashop-api';
import useAnalytics from '../useAnalytics';

export const useCart = () => {
  const { addOrRemoveFromCart } = useAnalytics();
  const auth = useAuth();
  const isAuthenticated = computed(() => auth.isAuthenticated);

  const state = useState('cart', () => ({
    loading: false,
    data: {} as GetCartItemsResponse['psdata'] | string,
  }))

  async function fetchCart() {
    state.value.loading = true;
    const data = await useSdk().commerce.getCartItems()
    state.value.loading = false;
    state.value.data = data;
  }

  watch(isAuthenticated, fetchCart);

  async function addToCart(product: UpdateCartItemRequest) {
    state.value.loading = true;

    await useSdk().commerce.updateCartItem({
      id_product: product.id_product,
      id_product_attribute: product.id_product_attribute,
      qty: product.qty,
      op: product.op || 'up',
    });

    if (product.op != 'down') {
      addOrRemoveFromCart(product, 'add_to_cart');
    }

    await fetchCart();
  }

  async function removeFromCart(product: UpdateCartItemRequest) {
    state.value.loading = true;

    await useSdk().commerce.removeCartItem({
      id_product: product.id_product,
      id_product_attribute: product.id_product_attribute,
    });

    addOrRemoveFromCart(product, 'remove_from_cart');

    await fetchCart();
  }

  async function applyCoupon(params: AddOrRemoveCouponRequest) {
    state.value.loading = true;

    const data = await useSdk().commerce.addOrRemoveCoupon({
      addDiscount: params.addDiscount,
      discount_name: params.discount_name,
    });

    await fetchCart();

    return data;
  }

  async function removeCoupon(params: AddOrRemoveCouponRequest) {
    state.value.loading = true;

    await useSdk().commerce.addOrRemoveCoupon({
      deleteDiscount: params.deleteDiscount,
    });

    await fetchCart();
  }

  return {
    data: computed(() => state.value.data || null),
    loading: computed(() => state.value.loading),
    execute: fetchCart,
    refresh: fetchCart,
    addToCart,
    removeFromCart,
    applyCoupon,
    removeCoupon,
  };
};
